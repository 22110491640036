export const dark = {
    body: '#fff8f8', //black shader
    text: '#0c0728',
    bodyRgba: '32, 32, 32',
    textRgba: '255, 255, 255',

    grey: '#f2eeee', //grey

    fontxs: '0.75em', // 1em = 16px
    fontsm: '.875em',
    fontmd: '1em',
    fontlg: '1.25em',
    fontxl: '2em',
    fontxxl: '3em',
    fontxxxl: '4.25em',
    fontBig: '10em',

    navWidth: '30%',

    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',

    // xPossible Font
    xpFont: 'New Xpossible Font'

}