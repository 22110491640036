import { createGlobalStyle } from 'styled-components'
import "@fontsource/bodoni-moda"
import "@fontsource/jost"
// import "@fontsource/cabin/variable.css"; // Contains ONLY variable weights and no other axes.
// import "@fontsource/cabin/variable-italic.css"; // Italic variant.
import "../assets/fonts/NewXpossibleFontRegular.eot"
import "../assets/fonts/NewXpossibleFontRegular.svg"
import "../assets/fonts/NewXpossibleFontRegular.ttf"
import "../assets/fonts/NewXpossibleFontRegular.woff"
import "../assets/fonts/NewXpossibleFontRegular.woff2"

const GlobalStyles = createGlobalStyle`

*, *::before, *::after {
    margin: 0;
    padding: 0;
    
}

body {
    font-family: "Bodoni Moda", monospace; 
    font-family: "Jost", sans-serif;
    overflow-x: hidden;
}

main {
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}

a{
    color: inherit;
    text-decoration: none;
    
}

`

export default GlobalStyles;