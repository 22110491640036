// import './App.css';
import { ThemeProvider } from 'styled-components'

import GlobalStyles from "./styles/GlobleStyles";
import { dark } from './styles/Themes'

import { useRef, useState, useEffect } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import 'locomotive-scroll/dist/locomotive-scroll.css'

import { AnimatePresence } from 'framer-motion';
import Header from './components/Header'
import Home from './sections/Home';
import Showcases from './sections/Showcases/Showcases';
import About from './sections/About';
import Shop from './sections/Shop';
import ScrollTriggerProxy from './components/ScrollTriggerProxy';
import Footer from './sections/./Footer/Footer';
import Form from './sections/./Questionnaire/Form';
import Loader from './components/Loader';

function App() {

  const containerRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 4500)

  }, [])



  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            // ... all available Locomotive Scroll instance options
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            }
          }}
          watch={
            [
              //..all the dependencies you want to watch to update the scroll.
              //  Basicaly, you would want to watch page/location changes
              //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
            ]
          }
          containerRef={containerRef}
        >
          <AnimatePresence>
            {loaded ? null : <Loader />}
          </AnimatePresence>
          <ScrollTriggerProxy />
          <AnimatePresence>
            <main className='App' data-scroll-container ref={containerRef}>
              <Header />
              <Home />
              <Showcases />
              <About />
              <Shop />
              <Form />
              <Footer />
            </main>
          </AnimatePresence>
        </LocomotiveScrollProvider>
      </ThemeProvider>

    </>
  );
}

export default App;
