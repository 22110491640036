import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { devices } from '../styles/device'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { motion } from 'framer-motion'

const Section = styled.section`
position: relative;
min-height: 100vh;
overflow: hidden;

background-color: ${props => props.theme.body};
font-family: "New Xpossible Font";
`
const Manifesto = styled(motion.div)`
position: absolute;
bottom: 15%;
left: 10%;
width: 80%;
margin: 0 auto;

display: flex;
flex-direction: column;
align-items: start;
justify-content: end;

font-size: ${props => props.theme.fontxl};

@media ${devices.tablet}{
    font-size: ${props => props.theme.fontxxxl};
    bottom: 10%;
    left: 5%;
};
@media ${devices.laptop}{
    font-size: 7rem;   
}
`
const Text = styled(motion.span)`
opacity: 1;
text-transform: uppercase;
padding: .5rem 0;
`



const Home = () => {
    gsap.registerPlugin(ScrollTrigger)

    const ref = useRef(null);
    useLayoutEffect(() => {
        const element = ref.current;
        const t1 = gsap.timeline()
        setTimeout(() => {
            t1.fromTo(
                element.querySelectorAll(".left"),
                {
                    opacity: 1,
                    x: 0
                },
                {
                    opacity: 0,
                    x: -600,
                    scrollTrigger: {
                        trigger: "#home",
                        start: "+=100",
                        end: "+=500",
                        scroller: ".App", // locomotive element
                        scrub: 2,
                        // pin: "#home"
                        // markers: true,
                    }
                }
            );
            ScrollTrigger.refresh()
        }, 1000);
        return () => {
            //clear instences
            t1.kill();
            ScrollTrigger.kill();
        };
    }, []);
    useLayoutEffect(() => {
        const element = ref.current;
        let t2 = gsap.timeline();
        setTimeout(() => {
            t2.fromTo(
                element.querySelectorAll(".right"),
                {
                    opacity: 1,
                    x: 0
                },
                {
                    opacity: 0,
                    x: 900,
                    scrollTrigger: {
                        trigger: element.querySelector("#home"),
                        start: "+=100",
                        end: "+=500",
                        scroller: ".App", // locomotive element
                        scrub: 2,
                        // pin: true,
                        // markers: true,
                    }
                }
            );
            ScrollTrigger.refresh()
        }, 1000);
        return () => {
            //clear instences
            t2.kill();
            ScrollTrigger.kill();
        };
    }, []);

    return (
        <Section id="home" ref={ref}>
            <Manifesto
                initial={{
                    y: '-5rem',
                }}
                animate={{
                    y: 0,
                }}
                transition={{
                    delay: 5,
                    duration: 5,
                    ease: 'easeInOut'
                }}
            >
                <Text className="left">We release</Text>
                <Text className="right">innocent soul.</Text>
                <Text className="left">We design, polish, </Text>
                <Text className="right">and make it beautiful.</Text>
            </Manifesto>
        </Section>
    )
}

export default Home