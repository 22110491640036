import React, { useState } from 'react'
import styled from 'styled-components'
import { motion, useCycle } from 'framer-motion'
import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { devices } from '../styles/device'

import Logo from './Logo'

//------------------- styling --------------------
const HeaderContainer = styled(motion.div)`
position: fixed;
position: relative;
width:100vw;
height: 4rem;
top: 0;
z-index: 6;

// box-shadow: ${props => props.theme.boxShadow};
transition: all 0.5s ease;

display: flex;
align-items: center;
justify-content: space-between;

padding: 0 .5rem 0 2rem;

@media ${devices.tablet}{
    padding: 0 2rem 0 3rem;
}
`

// nav
const NavContainer = styled(motion.nav)`
`
const MenuBtn = styled.button`
position: relative;
// top: 10%;
right: 0;
z-index: 10;

outline: none;
border: none;
background: transparent;

width: 3rem;
height: 3rem;
padding:.6rem 0.3rem;

display: flex;
flex-direction: column;
justify-content: space-between;

cursor: pointer;

`
// Menu list
const MenuItems = styled(motion.ul)`
position: absolute;
width: ${props => props.theme.navWidth};
height: 100vh;
right: ${props => props.click ? '0' : `-${props.theme.navWidth}`};

background-color: ${props => props.theme.body};
box-shadow: ${props => props.theme.boxShadow};
transition: all 1s ease;

border-radius: .7rem 0 0 .7rem;
padding-top: 10rem;
display: flex;
flex-direction: column;
justify-content: start;
align-items: center;
gap: 2rem;
list-style-type: none;
`
// list items
const MenuItem = styled(motion.li)`
text-transform: uppercase;
color: ${props => props.theme.text};
cursor: pointer;
`
// ----------------------------- animation ----------------------------------
// MenuBtn path animation 
const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="3"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        {...props}
    />
);

// Menu items animation
const liVariants = {
    open: {
        x: 0,
        opacity: 1,
        transition: {
            x: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        x: 50,
        opacity: 0,
        transition: {
            x: { stiffness: 1000 }
        }
    }
};
const ulVariants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};



const Header = () => {
    // hooks
    const [click, setClick] = useState(+false);
    const [isOpen, toggleOpen] = useCycle(false, true);

    // scrolling controller
    const { scroll } = useLocomotiveScroll();
    const handleScroll = (id) => {
        let elem = document.querySelector(id);
        setClick(!click);
        scroll.scrollTo(elem,
            {
                offset: '-100',
                duration: '2000',
                easing: [0.25, 0, 0.35, 1]
            }
        )
    }

    // Menu items
    const menuItems = [
        "home",
        "about",
        "contact",
        "showcase",
        "service"
    ]


    return (
        <HeaderContainer click={click}
            initial={{
                y: '-5rem',
            }}
            animate={{
                y: 0,
            }}
            transition={{
                delay: 3.5,
                duration: 5,
                ease: 'easeInOut'
            }}

        >
            <Logo />
            <NavContainer
                animate={isOpen ? "open" : "closed"}
            >
                <MenuBtn
                    click={click}
                    animate={isOpen ? "open" : "closed"}
                    onClick={() => {
                        toggleOpen()
                        setClick(!click)
                    }
                    }
                >
                    <svg width="23" height="23" viewBox="0 0 23 23">
                        <Path
                            variants={{
                                closed: { d: "M 2 2.5 L 20 2.5" },
                                open: { d: "M 3 16.5 L 17 2.5" }
                            }}
                        />
                        <Path
                            d="M 2 9.423 L 20 9.423"
                            variants={{
                                closed: { opacity: 1 },
                                open: { opacity: 0 }
                            }}
                            transition={{ duration: 0.1 }}
                        />
                        <Path
                            variants={{
                                closed: { d: "M 2 16.346 L 20 16.346" },
                                open: { d: "M 3 2.5 L 17 16.346" }
                            }}
                        />
                    </svg>
                </MenuBtn>

                <MenuItems
                    click={click}
                    drag='x'
                    dragConstraints={{
                        right: 50,
                        left: 0,
                    }}
                    dragElastic={true}
                    dragSnapToOrigin
                    variants={ulVariants}
                >
                    {menuItems.map((item) => (
                        <MenuItem key={item}
                            onClick={() => handleScroll(`#${item}`)}
                            variants={liVariants}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 1 }}
                        >{item}</MenuItem>
                    ))}

                </MenuItems>
            </NavContainer>
        </HeaderContainer>
    )
}

export default Header