import React, { useRef, useLayoutEffect } from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import { images } from '../constants'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import { devices } from '../styles/device'
import { } from '../'


const Section = styled.section`
position: relative;
min-height: 100vh;
width: 100vw;
margin: 0 auto;

display: flex;
flex-direction: column;
`
const Container = styled.div`
height: 100vh;
width: 100%;

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
const Pic = styled.div`
// ai jian
.pic-ai {
    // circle outline
    height: 270px;
    width: 270px;
    border-radius: 50%;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    background-color: transparent;

    // img
    position: relative;
    background-image: url(${images.ai_portait});
    background-repeat: no-repeat;
    background-position:center;
    background-position-y: 30px;
    background-size: 100%;

    // leaf
    &::after {
        content: "";
        position: absolute;
        right: -33%;
        top: -25%;
        height: 100%;
        width: 100%;
        background-image: url(${images.ai_leaf});
        background-size: contain;
        background-repeat:no-repeat;
        transition: all 0.5s ease-in-out;
    }

    // hover
    &:hover::after {
        transform: scale3d(1.1,1.2,0.5);
    }
}

// yuchen
.pic-yuchen {
    // circle outline
    height: 270px;
    width: 270px;
    border-radius: 50%;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    background-color: transparent;

    // image
    position: relative;
    background-image: url(${images.yuchen_portait});
    background-repeat: no-repeat;
    background-position:0px 15px;
    background-size: 110%;

    //----- head leaf -----
    &::after {
        content: "";
        position: absolute;
        right: 5%;
        bottom: -30%;
        height: 95%;
        width: 95%;
        background-image: url(${images.yuchen_leaf});
        background-size: contain;
        background-repeat:no-repeat;
        transition: all 0.5s ease-in-out;
    }

    // hover
    &:hover::after {
        transform: scale3d(1.1,1.2,0.5);
    }

}

`
const Name = styled.div`
text-align:center;
margin: 1em 0;
text-transform: capitalize;

`
const Intro = styled.div`
padding: 0 2em;
width:90%;

@media ${devices.tablet}{
    width:60%;
}
@media ${devices.laptop}{
    width:50%;
}
`

// ------------- content ------------------
const profiles = [
    {
        name: 'ai jian',
        title: 'creative developer / designer',
        pic: 'pic-ai',
        image: images.ai_portait,
        intro: `Ai is a frontend web developer who has been always curious about new
        technologies, loves all things aesthetically pleasing, and makes
        visuals come to life and react on screen.`
    },
    {
        name: 'yuchen yang',
        title: 'Talented graphic designer',
        pic: 'pic-yuchen',
        image: images.yuchen_portait,
        intro: `Yuchen is a professional practitioner of art and design. She gathers
        idea from her daily sketches and observation, develops concepts,
        creating graphic design from her small laptop. Always willing and
        has ability to work independently as well as part of a team. She is
        never tired of taking challenges.`
    },
]

const About = () => {

    return (
        <Section id="about">
            {profiles.map((profile, index) => {
                console.log(profile.image)
                return (
                    <Container key={profile.name + index}
                        whileInView={{ y: [100, 0], opacity: [0, 1], delay: 0.5 }}
                    >
                        <Pic data-scroll data-scroll-speed="1">
                            {/* <img src={profile.image} alt="" /> */}
                            <div className={profile.pic}></div>
                        </Pic>
                        <Name data-scroll data-scroll-speed="2">
                            <h2>{profile.name}</h2>
                            <p>{profile.title}</p>
                        </Name>
                        <Intro data-scroll data-scroll-speed="3">
                            {profile.intro}
                        </Intro>
                    </Container>
                )
            })}



        </Section>
    )
}

export default About