import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { images } from '../../constants'
import Showcase from './Showcase'

// ---------------------------- styling ------------------------------------
const Section = styled.section`
min-height: 100vh;
width: 100vw;
margin: 0 auto;
overflow-x: hidden;

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

position: relative;
`

// ----------------------------- project -----------------------------------
const projects = [
    {
        title: 'project1',
        year: '2021',
        company: '#',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus minus pariatur dolor similique eligendi!',
        imgURL: images.bookcover1
    },
    {
        title: 'project2',
        year: '2021',
        company: '#',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus minus pariatur dolor similique eligendi!',
        imgURL: images.magazing1
    },
    {
        title: 'project3',
        year: '2021',
        company: '#',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus minus pariatur dolor similique eligendi!',
        imgURL: images.graphicChart1
    },
]


const Showcases = () => {
    return (
        <Section id="showcase">
            {projects.map((project, index) => {
                console.log(project)
                return (
                    <Showcase key={project.title + '-' + index} project={project} />

                )
            })}
        </Section>
    )
}

export default Showcases