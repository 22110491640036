import React from 'react'

import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { images } from '../constants'
// import logo from '../../src/assets/gifs/loading.gif'

const Container = styled.div`
// position: absolute;
// top: 0;
// left: 0;
// z-index: 5;

// a {
//     display: flex;
//     align-items: flex-end;
// }

// svg {
//     width: 4rem;
//     height: auto;
//     overflow: visible;
//     stroke-linejoin: round;
//     stroke-linecap: round;

//     g {
//         path {
//             stroke: ${props => props.theme.text};
//         }
//     }
// }

`
const Text = styled(motion.span)`
    img {
        height: 1.1rem;
        width: auto;
    }
`

const pathVariants = {
    hidden: {
        opacity: 0,
        pathLength: 0
    },
    visible: {
        opacity: 1,
        pathLength: 1,

        transition: {
            duration: 1.5,
            delay: 4.5,
            ease: 'easeInOut'
        }
    },
}
const textVariants = {
    hidden: {
        opacity: 0,
        y: -50
    },
    visible: {
        opacity: 1,
        y: 0,

        transition: {
            duration: 3,
            delay: 2,
            ease: 'easeInOut'
        }
    },
}


const Logo = () => {
    return (
        <Container>
            <Link to="/">
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enable-background="new 0 0 24 24"
                    height="48px"
                    viewBox="0 0 24 24"
                    width="48px"
                    fill="none"
                >
                    <g>
                        <motion.path
                            variants={pathVariants}
                            initial="hidden"
                            animate="visible"

                            d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z" />
                    </g>
                </svg> */}


                <Text
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                >
                    <img src={images.logo} alt="logo" />
                </Text>
            </Link>
        </Container>
    );
}

export default Logo