import React, { useRef, useLayoutEffect } from 'react'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { devices } from '../../styles/device'


// ---------------------------- styling ------------------------------------
const Wrapper = styled.div`
min-height: 100vh;
width: 100%;
margin: 0 auto;

position: relative;
`
const Container = styled.div`
height: 100vh;
width: 100%;
margin: auto 0;

display: flex;

align-items: center;


position: relative;
// border: 5px solid yellow;

`

const Image = styled.div`
height: 100%;
width: 100%;
overflow-x: hidden;


img {
    height:70%;
    width: auto;
    background-size: cover;
    background-position: center;
    overflow: hidden;   
}

@media ${devices.tablet}{
    height: 100%;
    width: 65%;
    
    position: absolute;
    border-right: 2px solid ${props => props.theme.text};
    
    img {
        height: 90%;
        width: auto;
        background-size: cover;
        background-position: center;   
    }
    
};

`
const CaseTitle = styled.div`
height: 12%;
width: 100%;

position: absolute;
bottom: 20%;
right: 0;

padding: 1rem 2.5rem;
background-color: rgba(255, 192, 203, 0.742);


display: flex;
flex-direction: column;
align-items: start;

text-transform: capitalize;

@media ${devices.tablet}{
    height: 20%;
    width: 35%;

    position: absolute;
    top: 0;
    right: 0;

    border-bottom: 2px solid ${props => props.theme.text};
    
};


`

const Info = styled.div`
height: 18%;
width: 100%;

position: absolute;
bottom: 0;
right: 0;
padding: 4rem 2.5rem;

a {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 2.5rem;
    text-transform: capitalize;
}

@media ${devices.tablet}{
    height: 80%;
    width: 35%;

    a {
        top: 40%;
    }
    
};


`


const Showcase = ({ project }) => {
    //--------------------------- Animation -------------------------------------
    gsap.registerPlugin(ScrollTrigger)

    const ref = useRef(null);

    useLayoutEffect(() => {
        let element = ref.current;
        let t1 = gsap.timeline()
        setTimeout(() => {
            t1.fromTo(
                `#${project.title}`,
                {
                    opacity: 1,
                },
                {
                    opacity: 0,
                    scrollTrigger: {
                        trigger: element,
                        start: "top top",
                        end: "bottom 20%",
                        scroller: ".App", // locomotive element
                        scrub: 4,
                        snap: 4,
                        pin: `#${project.title}`,
                        // markers: true,
                    }
                }
            );
            ScrollTrigger.refresh()
        }, 1000);
        return () => {
            //clear instences
            t1.kill();
            ScrollTrigger.kill();
        };
    }, []);

    return (
        <Wrapper className='project-wapper' ref={ref} >
            <Container>
                <Image>
                    <motion.img src={project.imgURL} alt={project.title}
                        whileInView={{
                            y: [-100, 0], opacity: [0, 1], delay: 0.5
                        }}
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-offset="bottom top"
                    />
                </Image>
                <CaseTitle id={project.title}>
                    <div />
                    <h2>{project.title}</h2>
                    <p>{project.year}</p>
                    <p>{project.company}</p>
                </CaseTitle>
                <Info data-scroll data-scroll-speed="6" >
                    <p>{project.description}</p>
                    <a href="#">read more</a>
                </Info>
            </Container>
        </Wrapper>
    )
}
export default Showcase