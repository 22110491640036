import gsap, { snap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import { useRef } from "react";

import { motion } from "framer-motion";

import { images } from "../constants";
import { devices } from "../styles/device"

const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
margin: 0 auto;
overflow: hidden;

display: flex;
justify-content: flex-start;
align-items: flex-start;

position: relative;
`;

const Right = styled.div`
//   position: absolute;
//   left: 0;
height:100%;

background-color: ${(props) => props.theme.grey};
width: fit-content;
display: flex;
justify-content: flex-start;
align-items: center;

`;

const Item = styled(motion.div)`
width: 95vw;

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

padding: 2%;

@media ${devices.tablet}{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-around;

    padding: 3%;
}
@media ${devices.laptop}{
    padding: 4%;
}

// title
h1 {
    display: inline-block;
    width: fit-content;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    padding: 1rem 0;

    @media ${devices.tablet}{
        font-size: ${props => props.theme.fontxl};
    }
}
p {
    font-size: ${props => props.theme.fontmd};

    @media ${devices.tablet}{
        font-size: ${props => props.theme.fontlg};
    }
}

img {
    width: 100%;
    height: auto;
    padding: 2rem 0;
    // cursor: pointer;

    @media ${devices.tablet}{
        width: 50%;
    }
}


`;

const Step = ({ img, title, text }) => {
    return (
        <Item
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: false, amount: "all" }}
        >
            <div>
                <h1>{title}</h1>
                <p>{text}</p>
            </div>
            <motion.img src={img} alt={title}
                initial={{ filter: "grayscale(100%)", scale: 0.8 }}
                whileInView={{ filter: "grayscale(0%)", scale: 1 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: false, amount: "all" }}
            />
        </Item>
    );
};

const Shop = () => {

    // -------------------------- step contents -------------------------------------
    const steps = [
        {
            step: 'step1',
            text: `No idea should be directly thrown in the bin unless it has been tried.`,
            img: images.step1
        },
        {
            step: 'step2',
            text: `We can always find the jade beneath the skin of stone.`,
            img: images.step2
        },
        {
            step: 'step3',
            text: `We release the innocent soul, 
        we design, we polish, we make it beautiful.`,
            img: images.step3
        },
        {
            step: 'step4',
            text: `We build deeper connections with the world together.
        We show the œuvre together.`,
            img: images.step4
        }
    ]

    // -------------------------- scrolling + horizontal scroll --------------------------------------
    gsap.registerPlugin(ScrollTrigger);

    const ref = useRef(null);
    const horizontalRef = useRef(null);

    useLayoutEffect(() => {
        let element = ref.current;
        let scrollingElement = horizontalRef.current;

        let pinWrapWidth = scrollingElement.offsetWidth;
        let horizontalScrollLength = pinWrapWidth - window.innerWidth;

        let t1 = gsap.timeline();

        setTimeout(() => {
            t1.to(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "top top",
                    end: pinWrapWidth,
                    scroller: ".App", // locomotive element
                    scrub: true,
                    // pin: element,
                    // markers:true,
                },
                // we have to increase scrolling height of this section same as the scrolling element width
                height: `${scrollingElement.scrollWidth}px`,
                ease: "none,",
            });

            // Horizontal Scrolling
            t1.to(scrollingElement, {
                scrollTrigger: {
                    trigger: scrollingElement,
                    start: "top top",
                    end: pinWrapWidth,
                    scroller: ".App", // locomotive element
                    scrub: true,
                    pin: true,
                    // snap: true,
                    // markers:true,
                },
                // we have to increase scrolling height of this section same as the scrolling element width
                x: `-${horizontalScrollLength}`,
                ease: "none,",
            });
            ScrollTrigger.refresh();
        }, 1000);

        return () => {
            // Let's clear instances
            t1.kill();
            ScrollTrigger.kill();
        };
    }, []);

    return (
        <Section ref={ref} id="shop">

            <Right ref={horizontalRef}>

                {/* <Step img={images.step2} title="Tops" />
                <Step img={images.step3} title="Sweatshirts" />
                <Step img={images.step4} title="Ethnic Wear" /> */}
                {
                    steps.map((step, index) => {
                        return (
                            <Step key={index} img={step.img} title={step.step} text={step.text}

                            />
                        )
                    })
                }

            </Right>
        </Section>
    );
};

export default Shop;
